import axios from "axios";

import {
  CONTAIN_DETAILS_LIST_REQUEST,
  CONTAIN_DETAILS_LIST_SUCCESS,
  CONTAIN_DETAILS_LIST_FAIL,
  CONTAIN_DETAILS_CREATE_REQUEST,
  CONTAIN_DETAILS_CREATE_SUCCESS,
  CONTAIN_DETAILS_CREATE_FAIL,
  CONTAIN_DETAILS_UPDATE_REQUEST,
  CONTAIN_DETAILS_UPDATE_SUCCESS,
  CONTAIN_DETAILS_UPDATE_FAIL,
  CONTAIN_DETAILS_DELETE_REQUEST,
  CONTAIN_DETAILS_DELETE_SUCCESS,
  CONTAIN_DETAILS_DELETE_FAIL,
} from "../constants/containDetailsConstants";

export const containDetailList = () => async (dispatch) => {
  try {
    dispatch({
      type: CONTAIN_DETAILS_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://demo.clonestudiobd.com/api/contain_details",
      config
    );

    dispatch({
      type: CONTAIN_DETAILS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTAIN_DETAILS_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const containDetailCreateAction =
  (text_contain, sub_menu_id, psl, link_path, status, image_contain) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CONTAIN_DETAILS_CREATE_REQUEST,
      });

      const formData = new FormData();
      formData.append("sub_menu_id", sub_menu_id);
      formData.append("text_contain", text_contain);
      formData.append("image_contain", image_contain);
      formData.append("link_path", link_path);
      formData.append("psl", psl);
      formData.append("status", status);

      console.log("data", JSON.stringify(formData));

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      const { data } = await axios.post(
        "http://demo.clonestudiobd.com/api/contain_details",
        formData,
        config
      );

      dispatch({
        type: CONTAIN_DETAILS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTAIN_DETAILS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const containDetailUpdateAction =
  (id, text_contain, sub_menu_id, psl, link_path, status, image_contain) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CONTAIN_DETAILS_UPDATE_REQUEST,
      });

      console.log("id", id);

      const formData = new FormData();
      formData.append("sub_menu_id", sub_menu_id);
      formData.append("text_contain", text_contain);
      formData.append("image_contain", image_contain);
      formData.append("link_path", link_path);
      formData.append("psl", psl);
      formData.append("status", status);

      console.log("data", formData);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://demo.clonestudiobd.com/api/contain_details/${id}`,
        formData,
        config
      );

      dispatch({
        type: CONTAIN_DETAILS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTAIN_DETAILS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const containDetailsDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONTAIN_DETAILS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://demo.clonestudiobd.com/api/contain_details/${id}`,
      config
    );

    dispatch({
      type: CONTAIN_DETAILS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CONTAIN_DETAILS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
