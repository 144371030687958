import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import Fab from "@material-ui/core/Fab";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    setLastName,
    setRoleId,
    setMainMenuId,
    main_menu_id,
    setEmployeeId,
    setPhoto,
    menu_status,
    setUserStatus,
    item,
    subMenus,
    setSignPhoto,
    value,
    mainMenus,
    handleInputChange,
    error,
    helperText,
    handleUploadClick,
    photo,
    setShowImage,
    show_image,
    menu_image_preview,
    setMenuImagePreview,
    subMenuFilter,
    sub_menu_id,
    containType,
  } = props;

  console.log("edit data", item);

  const handleChange = (event) => {
    setUserStatus(event.target.value);
  };

  const handleShowImage = (event) => {
    setShowImage(event.target.value);
  };

  var topMenuName = item
    ? subMenus
        .filter((o1) => {
          return o1.id === item.sub_menu_id;
        })
        .map((item) => item.name)
    : null;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Main Menu *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setMainMenuId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? topMenuName : "None"}</option>
                {mainMenus
                  ? mainMenus.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Sub Menu *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setRoleId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? topMenuName : "None"}</option>
                {subMenuFilter
                  ? subMenuFilter.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          {containType[0] == "B" ? (
            <>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="text_contain"
                  variant="outlined"
                  fullWidth
                  id="text_contain"
                  label="Text Contain"
                  autoFocus
                  size="small"
                  multiline
                  rows={10}
                  error={error.text_contain}
                  helperText={helperText.text_contain}
                  defaultValue={item ? item.text_contain : null}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="psl"
                  variant="outlined"
                  required
                  fullWidth
                  id="user_id"
                  label="Print Serial"
                  autoFocus
                  size="small"
                  error={error.psl}
                  helperText={helperText.psl}
                  defaultValue={item ? item.psl : null}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="link_path"
                  variant="outlined"
                  fullWidth
                  id="user_id"
                  label="Link Path"
                  autoFocus
                  size="small"
                  error={error.link_path}
                  helperText={helperText.link_path}
                  defaultValue={item ? item.link_path : null}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                container
                xs={12}
                sm={6}
                justify="center"
                alignItems="center"
              >
                {menu_image_preview !== "" ? (
                  <div style={{ margin: 10 }}>
                    <img width="100%" src={menu_image_preview} />

                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        setPhoto("");
                        setMenuImagePreview("");
                      }}
                    >
                      <DeleteIcon fontSize="small" style={{ fill: "red" }} />
                    </IconButton>
                  </div>
                ) : (
                  <>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(event) => handleUploadClick(event)}
                    />
                    <label htmlFor="contained-button-file">
                      <Fab
                        component="span"
                        size="large"
                        style={{ height: 100, width: 100 }}
                      >
                        <AddPhotoAlternateIcon style={{ fontSize: 30 }} />
                      </Fab>
                    </label>
                  </>
                )}
              </Grid>
              <Grid
                container
                xs={12}
                sm={6}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} style={{ margin: 10 }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Status</FormLabel>
                    <RadioGroup
                      aria-label="status"
                      name="status1"
                      defaultValue={item ? item.status : menu_status}
                      onChange={handleChange}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="A"
                        control={<Radio size="small" />}
                        label={
                          <Typography style={{ fontSize: 14 }}>
                            Active
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="D"
                        control={<Radio size="small" />}
                        label={
                          <Typography style={{ fontSize: 14 }}>
                            Deactivate
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : containType[0] == "I" ? (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="psl"
                  variant="outlined"
                  required
                  fullWidth
                  id="user_id"
                  label="Print Serial"
                  autoFocus
                  size="small"
                  error={error.psl}
                  helperText={helperText.psl}
                  defaultValue={item ? item.psl : null}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="link_path"
                  variant="outlined"
                  fullWidth
                  id="user_id"
                  label="Link Path"
                  autoFocus
                  size="small"
                  error={error.link_path}
                  helperText={helperText.link_path}
                  defaultValue={item ? item.link_path : null}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                container
                xs={12}
                sm={6}
                justify="center"
                alignItems="center"
              >
                {menu_image_preview !== "" ? (
                  <div style={{ margin: 10 }}>
                    <img width="100%" src={menu_image_preview} />

                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        setPhoto("");
                        setMenuImagePreview("");
                      }}
                    >
                      <DeleteIcon fontSize="small" style={{ fill: "red" }} />
                    </IconButton>
                  </div>
                ) : (
                  <>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(event) => handleUploadClick(event)}
                    />
                    <label htmlFor="contained-button-file">
                      <Fab
                        component="span"
                        size="large"
                        style={{ height: 100, width: 100 }}
                      >
                        <AddPhotoAlternateIcon style={{ fontSize: 30 }} />
                      </Fab>
                    </label>
                  </>
                )}
              </Grid>
              <Grid
                container
                xs={12}
                sm={6}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} style={{ margin: 10 }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Status</FormLabel>
                    <RadioGroup
                      aria-label="status"
                      name="status1"
                      defaultValue={item ? item.status : menu_status}
                      onChange={handleChange}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="A"
                        control={<Radio size="small" />}
                        label={
                          <Typography style={{ fontSize: 14 }}>
                            Active
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="D"
                        control={<Radio size="small" />}
                        label={
                          <Typography style={{ fontSize: 14 }}>
                            Deactivate
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : containType[0] == "T" ? (
            <>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="text_contain"
                  variant="outlined"
                  fullWidth
                  id="text_contain"
                  label="Text Contain"
                  autoFocus
                  size="small"
                  multiline
                  rows={10}
                  error={error.text_contain}
                  helperText={helperText.text_contain}
                  defaultValue={item ? item.text_contain : null}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="psl"
                  variant="outlined"
                  required
                  fullWidth
                  id="user_id"
                  label="Print Serial"
                  autoFocus
                  size="small"
                  error={error.psl}
                  helperText={helperText.psl}
                  defaultValue={item ? item.psl : null}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="link_path"
                  variant="outlined"
                  fullWidth
                  id="user_id"
                  label="Link Path"
                  autoFocus
                  size="small"
                  error={error.link_path}
                  helperText={helperText.link_path}
                  defaultValue={item ? item.link_path : null}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid
                container
                xs={12}
                sm={6}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} style={{ margin: 10 }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Status</FormLabel>
                    <RadioGroup
                      aria-label="status"
                      name="status1"
                      defaultValue={item ? item.status : menu_status}
                      onChange={handleChange}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="A"
                        control={<Radio size="small" />}
                        label={
                          <Typography style={{ fontSize: 14 }}>
                            Active
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="D"
                        control={<Radio size="small" />}
                        label={
                          <Typography style={{ fontSize: 14 }}>
                            Deactivate
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
