export const TOP_MENUS_LIST_REQUEST = "TOP_MENUS_LIST_REQUEST";
export const TOP_MENUS_LIST_SUCCESS = "TOP_MENUS_LIST_SUCCESS";
export const TOP_MENUS_LIST_FAIL = "TOP_MENUS_LIST_FAIL";

export const TOP_MENUS_CREATE_REQUEST = "TOP_MENUS_CREATE_REQUEST";
export const TOP_MENUS_CREATE_SUCCESS = "TOP_MENUS_CREATE_SUCCESS";
export const TOP_MENUS_CREATE_FAIL = "TOP_MENUS_CREATE_FAIL";

export const TOP_MENUS_UPDATE_REQUEST = "TOP_MENUS_UPDATE_REQUEST";
export const TOP_MENUS_UPDATE_SUCCESS = "TOP_MENUS_UPDATE_SUCCESS";
export const TOP_MENUS_UPDATE_FAIL = "TOP_MENUS_UPDATE_FAIL";

export const TOP_MENUS_DELETE_REQUEST = "TOP_MENUS_DELETE_REQUEST";
export const TOP_MENUS_DELETE_SUCCESS = "TOP_MENUS_DELETE_SUCCESS";
export const TOP_MENUS_DELETE_FAIL = "TOP_MENUS_DELETE_FAIL";
