import axios from "axios";

import {
  TOP_MENUS_LIST_REQUEST,
  TOP_MENUS_LIST_SUCCESS,
  TOP_MENUS_LIST_FAIL,
  TOP_MENUS_CREATE_REQUEST,
  TOP_MENUS_CREATE_SUCCESS,
  TOP_MENUS_CREATE_FAIL,
  TOP_MENUS_UPDATE_REQUEST,
  TOP_MENUS_UPDATE_SUCCESS,
  TOP_MENUS_UPDATE_FAIL,
  TOP_MENUS_DELETE_REQUEST,
  TOP_MENUS_DELETE_SUCCESS,
  TOP_MENUS_DELETE_FAIL,
} from "../constants/topMenusConstants";

export const topMenusList = () => async (dispatch) => {
  try {
    dispatch({
      type: TOP_MENUS_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://demo.clonestudiobd.com/api/top_menus",
      config
    );

    dispatch({
      type: TOP_MENUS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOP_MENUS_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const topMenusCreateAction =
  (name, caption, psl, path, status, menu_image) => async (dispatch) => {
    try {
      dispatch({
        type: TOP_MENUS_CREATE_REQUEST,
      });

      console.log("name", name);

      const formData = new FormData();
      formData.append("name", name);
      formData.append("caption", caption);
      formData.append("menu_image", menu_image);
      formData.append("link_path", path);
      formData.append("psl", psl);
      formData.append("status", status);

      console.log("data", JSON.stringify(formData));

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      const { data } = await axios.post(
        "http://demo.clonestudiobd.com/api/top_menus",
        formData,
        config
      );

      dispatch({
        type: TOP_MENUS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TOP_MENUS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const topMenusUpdateAction =
  (id, name, caption, psl, path, status, menu_image) => async (dispatch) => {
    try {
      dispatch({
        type: TOP_MENUS_UPDATE_REQUEST,
      });

      console.log("id", id);

      const formData = new FormData();
      formData.append("name", name);
      formData.append("caption", caption);
      formData.append("menu_image", menu_image);
      formData.append("path", path);
      formData.append("psl", psl);
      formData.append("status", status);

      console.log("data", formData);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://demo.clonestudiobd.com/api/top_menus/${id}`,
        formData,
        config
      );

      dispatch({
        type: TOP_MENUS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TOP_MENUS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const topMenusDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TOP_MENUS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://demo.clonestudiobd.com/api/top_menus/${id}`,
      config
    );

    dispatch({
      type: TOP_MENUS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TOP_MENUS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
