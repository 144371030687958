export const MAIN_MENUS_LIST_REQUEST = "MAIN_MENUS_LIST_REQUEST";
export const MAIN_MENUS_LIST_SUCCESS = "MAIN_MENUS_LIST_SUCCESS";
export const MAIN_MENUS_LIST_FAIL = "MAIN_MENUS_LIST_FAIL";

export const MAIN_MENUS_CREATE_REQUEST = "MAIN_MENUS_CREATE_REQUEST";
export const MAIN_MENUS_CREATE_SUCCESS = "MAIN_MENUS_CREATE_SUCCESS";
export const MAIN_MENUS_CREATE_FAIL = "MAIN_MENUS_CREATE_FAIL";

export const MAIN_MENUS_UPDATE_REQUEST = "MAIN_MENUS_UPDATE_REQUEST";
export const MAIN_MENUS_UPDATE_SUCCESS = "MAIN_MENUS_UPDATE_SUCCESS";
export const MAIN_MENUS_UPDATE_FAIL = "MAIN_MENUS_UPDATE_FAIL";

export const MAIN_MENUS_DELETE_REQUEST = "MAIN_MENUS_DELETE_REQUEST";
export const MAIN_MENUS_DELETE_SUCCESS = "MAIN_MENUS_DELETE_SUCCESS";
export const MAIN_MENUS_DELETE_FAIL = "MAIN_MENUS_DELETE_FAIL";
