import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { topMenusList } from "../../redux/actions/topMenusActions";
import { mainMenusList } from "../../redux/actions/mainMenusAction";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();

  const topMenuList = useSelector((state) => state.topMenuList);
  const { topMenus } = topMenuList;

  const mainMenuList = useSelector((state) => state.mainMenuList);
  const { mainMenus } = mainMenuList;


  useEffect(() => {
    dispatch(topMenusList());
    dispatch(mainMenusList());
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <Table
          topMenus={topMenus.data ? topMenus.data : []}
          mainMenus={mainMenus.data ? mainMenus.data : []}
        />
      </div>
    </div>
  );
};

export default Body;
