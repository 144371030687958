import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userLoginReducer } from "./components/redux/reducers/userReducers";
import {
  moduleReducerGlobal,
  moduleReducerSidebar,
} from "./components/redux/reducers/moduleReducers";

import { toggleSidebarReducer } from "./components/redux/reducers/globalStateReducers";
import {
  subMenuListReducer,
  subMenuCreateReducer,
  subMenuUpdateReducer,
  subMenuDeleteReducer,
} from "./components/redux/reducers/subMenusReducers";
import { mainMenuListReducer } from "./components/redux/reducers/mainMenusReducers";

import { topMenuListReducer } from "./components/redux/reducers/topMenusReducers";

import { carouselListReducer } from "./components/redux/reducers/carouselReducers";

import { containDetailListReducer } from "./components/redux/reducers/containDetailsReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  module: moduleReducerSidebar,
  moduleGlobal: moduleReducerGlobal,
  leftSidebar: toggleSidebarReducer,
  subMenuList: subMenuListReducer,
  subMenuCreate: subMenuCreateReducer,
  mainMenuList: mainMenuListReducer,
  topMenuList: topMenuListReducer,
  carouselListItem: carouselListReducer,
  containDetailListItem: containDetailListReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
