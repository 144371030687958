import {
  TOP_MENUS_LIST_REQUEST,
  TOP_MENUS_LIST_SUCCESS,
  TOP_MENUS_LIST_FAIL,
  TOP_MENUS_CREATE_REQUEST,
  TOP_MENUS_CREATE_SUCCESS,
  TOP_MENUS_CREATE_FAIL,
  TOP_MENUS_UPDATE_REQUEST,
  TOP_MENUS_UPDATE_SUCCESS,
  TOP_MENUS_UPDATE_FAIL,
  TOP_MENUS_DELETE_REQUEST,
  TOP_MENUS_DELETE_SUCCESS,
  TOP_MENUS_DELETE_FAIL,
} from "../constants/topMenusConstants";

export const topMenuListReducer = (state = { topMenus: [] }, action) => {
  switch (action.type) {
    case TOP_MENUS_LIST_REQUEST:
      return { loading: true, topMenus: [] };
    case TOP_MENUS_LIST_SUCCESS:
      return { loading: false, topMenus: action.payload };
    case TOP_MENUS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const topMenuCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOP_MENUS_CREATE_REQUEST:
      return { loading: true };
    case TOP_MENUS_CREATE_SUCCESS:
      return { loading: false, topMenuCreates: action.payload };
    case TOP_MENUS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const topMenuUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOP_MENUS_UPDATE_REQUEST:
      return { loading: true };
    case TOP_MENUS_UPDATE_SUCCESS:
      return { loading: false, topMenuUpdates: action.payload };
    case TOP_MENUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const topMenuDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TOP_MENUS_DELETE_REQUEST:
      return { loading: true };
    case TOP_MENUS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TOP_MENUS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
