import {
  MAIN_MENUS_LIST_REQUEST,
  MAIN_MENUS_LIST_SUCCESS,
  MAIN_MENUS_LIST_FAIL,
  MAIN_MENUS_CREATE_REQUEST,
  MAIN_MENUS_CREATE_SUCCESS,
  MAIN_MENUS_CREATE_FAIL,
  MAIN_MENUS_UPDATE_REQUEST,
  MAIN_MENUS_UPDATE_SUCCESS,
  MAIN_MENUS_UPDATE_FAIL,
  MAIN_MENUS_DELETE_REQUEST,
  MAIN_MENUS_DELETE_SUCCESS,
  MAIN_MENUS_DELETE_FAIL,
} from "../constants/mainMenusConstants";

export const mainMenuListReducer = (state = { mainMenus: [] }, action) => {
  switch (action.type) {
    case MAIN_MENUS_LIST_REQUEST:
      return { loading: true, mainMenus: [] };
    case MAIN_MENUS_LIST_SUCCESS:
      return { loading: false, mainMenus: action.payload };
    case MAIN_MENUS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const mainMenuCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIN_MENUS_CREATE_REQUEST:
      return { loading: true };
    case MAIN_MENUS_CREATE_SUCCESS:
      return { loading: false, mainMenuCreates: action.payload };
    case MAIN_MENUS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const mainMenuUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIN_MENUS_UPDATE_REQUEST:
      return { loading: true };
    case MAIN_MENUS_UPDATE_SUCCESS:
      return { loading: false, mainMenuUpdates: action.payload };
    case MAIN_MENUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const mainMenuDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIN_MENUS_DELETE_REQUEST:
      return { loading: true };
    case MAIN_MENUS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case MAIN_MENUS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
