export const SUB_MENUS_LIST_REQUEST = "SUB_MENUS_LIST_REQUEST";
export const SUB_MENUS_LIST_SUCCESS = "SUB_MENUS_LIST_SUCCESS";
export const SUB_MENUS_LIST_FAIL = "SUB_MENUS_LIST_FAIL";

export const SUB_MENUS_CREATE_REQUEST = "SUB_MENUS_CREATE_REQUEST";
export const SUB_MENUS_CREATE_SUCCESS = "SUB_MENUS_CREATE_SUCCESS";
export const SUB_MENUS_CREATE_FAIL = "SUB_MENUS_CREATE_FAIL";

export const SUB_MENUS_UPDATE_REQUEST = "SUB_MENUS_UPDATE_REQUEST";
export const SUB_MENUS_UPDATE_SUCCESS = "SUB_MENUS_UPDATE_SUCCESS";
export const SUB_MENUS_UPDATE_FAIL = "SUB_MENUS_UPDATE_FAIL";

export const SUB_MENUS_DELETE_REQUEST = "SUB_MENUS_DELETE_REQUEST";
export const SUB_MENUS_DELETE_SUCCESS = "SUB_MENUS_DELETE_SUCCESS";
export const SUB_MENUS_DELETE_FAIL = "SUB_MENUS_DELETE_FAIL";
