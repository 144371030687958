import {
  SUB_MENUS_LIST_REQUEST,
  SUB_MENUS_LIST_SUCCESS,
  SUB_MENUS_LIST_FAIL,
  SUB_MENUS_CREATE_REQUEST,
  SUB_MENUS_CREATE_SUCCESS,
  SUB_MENUS_CREATE_FAIL,
  SUB_MENUS_UPDATE_REQUEST,
  SUB_MENUS_UPDATE_SUCCESS,
  SUB_MENUS_UPDATE_FAIL,
  SUB_MENUS_DELETE_REQUEST,
  SUB_MENUS_DELETE_SUCCESS,
  SUB_MENUS_DELETE_FAIL,
} from "../constants/subMenusConstant";

export const subMenuListReducer = (state = { subMenus: [] }, action) => {
  switch (action.type) {
    case SUB_MENUS_LIST_REQUEST:
      return { loading: true, subMenus: [] };
    case SUB_MENUS_LIST_SUCCESS:
      return { loading: false, subMenus: action.payload };
    case SUB_MENUS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subMenuCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_MENUS_CREATE_REQUEST:
      return { loading: true };
    case SUB_MENUS_CREATE_SUCCESS:
      return { loading: false, subMenuCreates: action.payload };
    case SUB_MENUS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subMenuUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_MENUS_UPDATE_REQUEST:
      return { loading: true };
    case SUB_MENUS_UPDATE_SUCCESS:
      return { loading: false, subMenuUpdates: action.payload };
    case SUB_MENUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subMenuDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_MENUS_DELETE_REQUEST:
      return { loading: true };
    case SUB_MENUS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SUB_MENUS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
