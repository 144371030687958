import axios from "axios";

import {
  CAROUSEL_LIST_REQUEST,
  CAROUSEL_LIST_SUCCESS,
  CAROUSEL_LIST_FAIL,
  CAROUSEL_CREATE_REQUEST,
  CAROUSEL_CREATE_SUCCESS,
  CAROUSEL_CREATE_FAIL,
  CAROUSEL_UPDATE_REQUEST,
  CAROUSEL_UPDATE_SUCCESS,
  CAROUSEL_UPDATE_FAIL,
  CAROUSEL_DELETE_REQUEST,
  CAROUSEL_DELETE_SUCCESS,
  CAROUSEL_DELETE_FAIL,
} from "../constants/carouselConstants";

export const carouselList = () => async (dispatch) => {
  try {
    dispatch({
      type: CAROUSEL_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://demo.clonestudiobd.com/api/carousel",
      config
    );

    dispatch({
      type: CAROUSEL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAROUSEL_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const carouselCreateAction =
  (
    title,
    sub_title,
    description,
    btn_title,
    psl,
    btn_link,
    status,
    text_position,
    anim_type,
    image
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CAROUSEL_CREATE_REQUEST,
      });

      const formData = new FormData();
      formData.append("title", title);
      formData.append("sub_title", sub_title);
      formData.append("description", description);
      formData.append("btn_title", btn_title);
      formData.append("btn_link", btn_link);
      formData.append("psl", psl);
      formData.append("status", status);
      formData.append("text_position", text_position);
      formData.append("anim_type", anim_type);
      formData.append("image", image);

      console.log("data", JSON.stringify(formData));

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      const { data } = await axios.post(
        "http://demo.clonestudiobd.com/api/carousel",
        formData,
        config
      );

      dispatch({
        type: CAROUSEL_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CAROUSEL_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const carouselUpdateAction =
  (
    id,
    title,
    sub_title,
    description,
    btn_title,
    psl,
    btn_link,
    status,
    text_position,
    anim_type,
    image
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CAROUSEL_UPDATE_REQUEST,
      });

      console.log("id", id);

      const formData = new FormData();
      formData.append("title", title);
      formData.append("sub_title", sub_title);
      formData.append("description", description);
      formData.append("btn_title", btn_title);
      formData.append("btn_link", btn_link);
      formData.append("psl", psl);
      formData.append("status", status);
      formData.append("text_position", text_position);
      formData.append("anim_type", anim_type);
      formData.append("image", image);

      console.log("data", formData);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://demo.clonestudiobd.com/api/carousel/${id}`,
        formData,
        config
      );

      dispatch({
        type: CAROUSEL_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CAROUSEL_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const carouselDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CAROUSEL_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://demo.clonestudiobd.com/api/carousel/${id}`,
      config
    );

    dispatch({
      type: CAROUSEL_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CAROUSEL_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
