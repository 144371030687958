export const CONTAIN_DETAILS_LIST_REQUEST = "CONTAIN_DETAILS_LIST_REQUEST";
export const CONTAIN_DETAILS_LIST_SUCCESS = "CONTAIN_DETAILS_LIST_SUCCESS";
export const CONTAIN_DETAILS_LIST_FAIL = "CONTAIN_DETAILS_LIST_FAIL";

export const CONTAIN_DETAILS_CREATE_REQUEST = "CONTAIN_DETAILS_CREATE_REQUEST";
export const CONTAIN_DETAILS_CREATE_SUCCESS = "CONTAIN_DETAILS_CREATE_SUCCESS";
export const CONTAIN_DETAILS_CREATE_FAIL = "CONTAIN_DETAILS_CREATE_FAIL";

export const CONTAIN_DETAILS_UPDATE_REQUEST = "CONTAIN_DETAILS_UPDATE_REQUEST";
export const CONTAIN_DETAILS_UPDATE_SUCCESS = "CONTAIN_DETAILS_UPDATE_SUCCESS";
export const CONTAIN_DETAILS_UPDATE_FAIL = "CONTAIN_DETAILS_UPDATE_FAIL";

export const CONTAIN_DETAILS_DELETE_REQUEST = "CONTAIN_DETAILS_DELETE_REQUEST";
export const CONTAIN_DETAILS_DELETE_SUCCESS = "CONTAIN_DETAILS_DELETE_SUCCESS";
export const CONTAIN_DETAILS_DELETE_FAIL = "CONTAIN_DETAILS_DELETE_FAIL";
