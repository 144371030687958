import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { carouselList } from "../../redux/actions/carouselActions";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();

  const carouselListItem = useSelector((state) => state.carouselListItem);
  const { carousels } = carouselListItem;

  useEffect(() => {
    dispatch(carouselList());
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <Table carousels={carousels.data ? carousels.data : []} />
      </div>
    </div>
  );
};

export default Body;
