import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  containDetailCreateAction,
  containDetailList,
} from "../../redux/actions/containDetailsActions";
import { CreateValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MenuBookIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, subMenus, setNotify, mainMenus } = props;

  const [sub_menu_id, setRoleId] = React.useState("");

  const [main_menu_id, setMainMenuId] = React.useState("");
  const [subMenuFilter, setSubMenuFilter] = React.useState("");
  const [containType, setContainType] = React.useState("");

  const [image_contain, setPhoto] = React.useState("");
  const [menu_image_preview, setMenuImagePreview] = React.useState("");

  const [status, setUserStatus] = React.useState("A");
  const [show_image, setShowImage] = React.useState("Y");

  const dispatch = useDispatch();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const text_contain = values.text_contain;
  const psl = values.psl;
  const link_path = values.link_path;

  console.log(
    "post data",
    text_contain,
    sub_menu_id,
    psl,
    link_path,
    status,
    image_contain
  );

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("gggggggg");
      dispatch(
        containDetailCreateAction(
          text_contain,
          sub_menu_id,
          psl,
          link_path,
          status,
          image_contain
        )
      );
      setOpenPopup(false);
      setTimeout(() => {
        dispatch(containDetailList());
      }, 2000);
      // setNotify({
      //   isOpen: true,
      //   message: 'Submitted Successfully!',
      //   type: 'success',
      // });
      resetForm();
      setContainType("");
      setPhoto("");
      setMenuImagePreview("");
      setRoleId("");
      setMainMenuId("");
      setSubMenuFilter("");
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
    setContainType("");
    setPhoto("");
    setMenuImagePreview("");
  };

  const handleUploadClick = (event) => {
    console.log(event);
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);
    console.log("file", file);

    reader.onloadend = function (e) {
      setMenuImagePreview([reader.result]);
    };
    console.log(url); // Would see a path?

    setPhoto(file);
  };

  React.useEffect(() => {
    if (main_menu_id !== "" && subMenus) {
      const itemFilter = subMenus
        .filter((o1) => {
          return o1.main_menu_id == main_menu_id;
        })
        .map((item) => item);

      console.log("itemFilter", itemFilter);

      setSubMenuFilter(itemFilter);
    }
  }, [main_menu_id, subMenus]);

  React.useEffect(() => {
    if (sub_menu_id !== "" && subMenus) {
      const contain_type = subMenus
        .filter((o1) => {
          return o1.id == sub_menu_id;
        })
        .map((item) => item.contain_type);

      console.log("contain_type", contain_type);

      setContainType(contain_type);
    }
  }, [sub_menu_id, subMenus]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        New Contain Detail Create
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          setRoleId={setRoleId}
          setMainMenuId={setMainMenuId}
          sub_menu_id={sub_menu_id}
          main_menu_id={main_menu_id}
          subMenuFilter={subMenuFilter}
          containType={containType}
          setPhoto={setPhoto}
          menu_image_preview={menu_image_preview}
          setMenuImagePreview={setMenuImagePreview}
          photo={image_contain}
          setUserStatus={setUserStatus}
          menu_status={status}
          setShowImage={setShowImage}
          show_image={show_image}
          subMenus={subMenus}
          mainMenus={mainMenus}
          value={values}
          handleInputChange={handleInputChange}
          handleUploadClick={handleUploadClick}
          error={errors}
          helperText={errors}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
