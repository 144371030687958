import {
  CONTAIN_DETAILS_LIST_REQUEST,
  CONTAIN_DETAILS_LIST_SUCCESS,
  CONTAIN_DETAILS_LIST_FAIL,
  CONTAIN_DETAILS_CREATE_REQUEST,
  CONTAIN_DETAILS_CREATE_SUCCESS,
  CONTAIN_DETAILS_CREATE_FAIL,
  CONTAIN_DETAILS_UPDATE_REQUEST,
  CONTAIN_DETAILS_UPDATE_SUCCESS,
  CONTAIN_DETAILS_UPDATE_FAIL,
  CONTAIN_DETAILS_DELETE_REQUEST,
  CONTAIN_DETAILS_DELETE_SUCCESS,
  CONTAIN_DETAILS_DELETE_FAIL,
} from "../constants/containDetailsConstants";

export const containDetailListReducer = (
  state = { containDetails: [] },
  action
) => {
  switch (action.type) {
    case CONTAIN_DETAILS_LIST_REQUEST:
      return { loading: true, containDetails: [] };
    case CONTAIN_DETAILS_LIST_SUCCESS:
      return { loading: false, containDetails: action.payload };
    case CONTAIN_DETAILS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const containDetailCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTAIN_DETAILS_CREATE_REQUEST:
      return { loading: true };
    case CONTAIN_DETAILS_CREATE_SUCCESS:
      return { loading: false, containDetailCreates: action.payload };
    case CONTAIN_DETAILS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const containDetailUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTAIN_DETAILS_UPDATE_REQUEST:
      return { loading: true };
    case CONTAIN_DETAILS_UPDATE_SUCCESS:
      return { loading: false, containDetailUpdates: action.payload };
    case CONTAIN_DETAILS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const containDetailDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTAIN_DETAILS_DELETE_REQUEST:
      return { loading: true };
    case CONTAIN_DETAILS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CONTAIN_DETAILS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
