import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import Fab from "@material-ui/core/Fab";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    setPhoto,
    menu_status,
    setUserStatus,
    item,
    handleInputChange,
    error,
    helperText,
    handleUploadClick,
    setShowImage,
    menu_image_preview,
    setMenuImagePreview,
  } = props;

  console.log("edit data", item);

  const handleChange = (event) => {
    setUserStatus(event.target.value);
  };

  const handleShowImage = (event) => {
    setShowImage(event.target.value);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="name"
              variant="outlined"
              required
              fullWidth
              id="name"
              label="Top-Menu Name"
              autoFocus
              size="small"
              error={error.name}
              helperText={helperText.name}
              defaultValue={item ? item.name : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="caption"
              required
              variant="outlined"
              fullWidth
              id="caption"
              label="Caption"
              autoFocus
              size="small"
              multiline
              rows={3}
              error={error.caption}
              helperText={helperText.caption}
              defaultValue={item ? item.caption : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="psl"
              variant="outlined"
              required
              fullWidth
              id="user_id"
              label="Print Serial"
              autoFocus
              size="small"
              error={error.psl}
              helperText={helperText.psl}
              defaultValue={item ? item.psl : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="path"
              variant="outlined"
              fullWidth
              id="user_id"
              label="Path"
              autoFocus
              size="small"
              error={error.path}
              helperText={helperText.path}
              defaultValue={item ? item.path : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid container xs={12} sm={6} justify="center" alignItems="center">
            {menu_image_preview !== "" ? (
              <div style={{ margin: 10 }}>
                <img width="100%" src={menu_image_preview} />

                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    setPhoto("");
                    setMenuImagePreview("");
                  }}
                >
                  <DeleteIcon fontSize="small" style={{ fill: "red" }} />
                </IconButton>
              </div>
            ) : (
              <>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(event) => handleUploadClick(event)}
                />
                <label htmlFor="contained-button-file">
                  <Fab
                    component="span"
                    size="large"
                    style={{ height: 100, width: 100 }}
                  >
                    <AddPhotoAlternateIcon style={{ fontSize: 30 }} />
                  </Fab>
                </label>
              </>
            )}
          </Grid>
          <Grid container xs={12} sm={6} justify="center" alignItems="center">
            <Grid item xs={12} sm={12} style={{ margin: 10 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Menu Status</FormLabel>
                <RadioGroup
                  aria-label="status"
                  name="status1"
                  defaultValue={item ? item.status : menu_status}
                  onChange={handleChange}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="A"
                    control={<Radio size="small" />}
                    label={
                      <Typography style={{ fontSize: 14 }}>Active</Typography>
                    }
                  />
                  <FormControlLabel
                    value="D"
                    control={<Radio size="small" />}
                    label={
                      <Typography style={{ fontSize: 14 }}>
                        Deactivate
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={12} style={{ margin: 10 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Show Image</FormLabel>
                <RadioGroup
                  aria-label="status"
                  name="status1"
                  defaultValue={item ? item.show_image : show_image}
                  onChange={handleShowImage}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="Y"
                    control={<Radio size="small" />}
                    label={
                      <Typography style={{ fontSize: 14 }}>Show</Typography>
                    }
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio size="small" />}
                    label={
                      <Typography style={{ fontSize: 14 }}>Hide</Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid> */}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
