import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    title: "",
    sub_title: "",
    description: "",
    psl: "",
    btn_title: "",
    btn_link: "",
    text_position: "",
    anim_type: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    title: "",
    sub_title: "",
    description: "",
    psl: "",
    btn_title: "",
    btn_link: "",
    text_position: "",
    anim_type: "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      title: item.title,
      sub_title: item.sub_title,
      description: item.description,
      psl: item.psl,
      btn_title: item.btn_title,
      btn_link: item.btn_link,
      text_position: item.text_position,
      anim_type: item.anim_type,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
