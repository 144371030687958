import {
  CAROUSEL_LIST_REQUEST,
  CAROUSEL_LIST_SUCCESS,
  CAROUSEL_LIST_FAIL,
  CAROUSEL_CREATE_REQUEST,
  CAROUSEL_CREATE_SUCCESS,
  CAROUSEL_CREATE_FAIL,
  CAROUSEL_UPDATE_REQUEST,
  CAROUSEL_UPDATE_SUCCESS,
  CAROUSEL_UPDATE_FAIL,
  CAROUSEL_DELETE_REQUEST,
  CAROUSEL_DELETE_SUCCESS,
  CAROUSEL_DELETE_FAIL,
} from "../constants/carouselConstants";

export const carouselListReducer = (state = { carousels: [] }, action) => {
  switch (action.type) {
    case CAROUSEL_LIST_REQUEST:
      return { loading: true, carousels: [] };
    case CAROUSEL_LIST_SUCCESS:
      return { loading: false, carousels: action.payload };
    case CAROUSEL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const carouselCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CAROUSEL_CREATE_REQUEST:
      return { loading: true };
    case CAROUSEL_CREATE_SUCCESS:
      return { loading: false, carouselCreates: action.payload };
    case CAROUSEL_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const carouselUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CAROUSEL_UPDATE_REQUEST:
      return { loading: true };
    case CAROUSEL_UPDATE_SUCCESS:
      return { loading: false, carouselUpdates: action.payload };
    case CAROUSEL_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const carouselDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CAROUSEL_DELETE_REQUEST:
      return { loading: true };
    case CAROUSEL_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CAROUSEL_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
